import { data } from './data'
import styles from './SecondTabMenu.module.css'

const SecondTabMenu = () => {
  const menuData: any = data

  return (
    <div className={styles.container}>
      {menuData.map((item: any, index: number) => (
        <div className={styles.menuItem}>
          {item.items.map((item: any, index: number) => (
            <div className={styles.menuSingleItem}>{item.title && <span>{item.title}</span>}</div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default SecondTabMenu
