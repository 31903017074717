import styles from './Six65.module.css'

const Six65 = () => {
  return (
    <div className={styles.container}>
      <img src='/media/six65/page1.png' alt='' />
      <img src='/media/six65/page2.png' alt='' />
    </div>
  )
}

export default Six65
