import { useState, useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
// Components
import GlobalLoading from 'components/GlobalLoading/GlobalLoading'
import Home from 'pages/Home/Home'

// Contants
import { API_URL } from 'utils/constants/constants'

// Models
import { Menu } from 'api/Api'
import Six65 from 'components/PdfHosting/Six65/Six65'
import CroniesKanlikavak from 'components/PdfHosting/CroniesKanlikavak/CroniesKanlikavak'
import VibesTakeaway from 'components/vibesTakeaway/VibesTakeaway'
import ThemePicker from 'components/ThemePickler/ThemePicker'
import NotFound from 'pages/NotFound/NotFound'

const App = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [menuDetails, setMenuDetails] = useState<Menu | null>(null)
  const [globalLoading, setGlobalLoading] = useState(true)

  // Api call to get menu details and set globalLoading to false
  const getMenuDetails = async () => {
    try {
      setGlobalLoading(true)
      const response = await axios.get(`${API_URL}menu/${location.pathname.split('/')[2]}`)
      setMenuDetails(response.data)

      setTimeout(() => {
        setGlobalLoading(false)
      }, 2000)
    } catch (error) {
      setGlobalLoading(false)
      navigate('/not-found')
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      !location.pathname.includes('six65-restaurant') &&
      !location.pathname.includes('vibes-takeaway')
    ) {
      getMenuDetails()
    }
    setGlobalLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (globalLoading) {
    return <GlobalLoading />
  }

  return (
    <Routes>
      {/* Home */}
      <Route path='/' element={<Home />} />

      {/* PDF HOSTING - SIX65 */}
      <Route path='menu/cronies-kanlikavak' element={<CroniesKanlikavak />} />
      <Route path='menu/six65-restaurant' element={<Six65 />} />
      <Route path='menu/vibes-takeaway' element={<VibesTakeaway />} />

      {/* Main route for customers*/}
      <Route
        path='menu/:id'
        element={<ThemePicker selectedTheme={menuDetails?.theme} data={menuDetails} />}
      />

      {/* 404 */}
      <Route path='*' element={<NotFound />}></Route>
    </Routes>
  )
}

export default App
