import styles from './Home.module.css'

const Home = () => {
  return (
    <div className={styles.container}>
      {/* Logo */}
      <div className={styles.logo}>
        <img src='/media/logo/menuticaLogo.png' alt='logo' />
      </div>

      {/* CTA BUTTON */}
      <div className={styles.cta}>
        <span>Order your QR menu today !</span>
        <button
          onClick={() =>
            window.open(
              'https://www.fiverr.com/berkantcen/do-online-editable-menu-design-food-menu-restaurant-menu',
              '_blank',
            )
          }
        >
          Contact on Fiverr
        </button>
      </div>
    </div>
  )
}

export default Home
