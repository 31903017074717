// Styles
import styles from './NotFound.module.css'

const NotFound = () => {
  return (
    <div className={styles.container}>
      {/* Image */}
      <div className={styles.imageContainer}>
        <img src='/media/graphics/not-found.png' alt='' />
      </div>
      <div className={styles.ctaContainer}>
        <span>We're sorry, the restaurant you're looking for is not registered in our system.</span>
        <button>Contact on Fiverr</button>
      </div>
    </div>
  )
}

export default NotFound
