import styles from './DetailItem.module.css'
import classNames from 'classnames'

import { MenuItem } from 'api/Api'

interface DetailItemProps {
  item: MenuItem
  index: number
}

const DetailItem = ({ item, index }: DetailItemProps) => {
  const handleMultiplePrice = () => {
    if (!item.priceOptions) return

    const allPrices = item.priceOptions

    const newList = []

    for (const price of allPrices) {
      let formattedPrice = `${price.title} - ${price.price}₺`
      newList.push(formattedPrice)
    }

    const formattedPrices = newList.join(' - ')

    return formattedPrices
  }

  return (
    <div
      className={classNames(styles.detailContainer, {
        [styles.topBorder]: index === 0,
      })}
      key={index}
    >
      <div className={styles.itemDetails}>
        <span className={styles.title}>{item.title}</span>
        <span className={styles.subtitle}>{item.description}</span>
      </div>
      {/* Price */}
      <span className={styles.price}>
        {item.priceOptions ? handleMultiplePrice() : item.finalPrice + '₺'}
      </span>
    </div>
  )
}

export default DetailItem
