// Models
import { Menu } from 'api/Api'

// Styles
import styles from './Categories.module.css'
import SingleCategory from './components/SingleCategory/SingleCategory'
interface CategoriesProps {
  data: Menu | null
  setSelectedCategory: (category: string | undefined) => void
}

const Categories = ({ data, setSelectedCategory }: CategoriesProps) => {
  return (
    <>
      <div className={styles.categoryContainer}>
        {data?.categories?.map((category, idx) => (
          <SingleCategory key={idx} category={category} setSelectedCategory={setSelectedCategory} />
        ))}
      </div>
    </>
  )
}

export default Categories
