import styles from './FirstTabMenu.module.css'

import { data } from './data'

const FirstTabMenu = () => {
  const menuData: any = data

  return (
    <div className={styles.container}>
      {menuData.map((item: any, index: number) => (
        <div key={index} className={styles.menuItem}>
          {/* Title & Price */}

          {item.title && (
            <div dangerouslySetInnerHTML={{ __html: item.title }} className={styles.dishTitle} />
          )}

          {/* How to  */}
          {item.guide && (
            <div dangerouslySetInnerHTML={{ __html: item.guide }} className={styles.guide} />
          )}

          {item.underTitleOptions && (
            <div className={styles.underTitleOptions}>{item.underTitleOptions}</div>
          )}

          {/* Warning */}
          {item.warning && <div className={styles.warning}>{item.warning}</div>}
          {/* Options */}

          {item.options &&
            item.options.map((option: any, index: number) => (
              <div className={styles.optionContainer} key={index}>
                {option.title && <div className={styles.optionTitle}>{option.title}</div>}

                {option.warning && <div className={styles.warning}>{option.warning}</div>}

                <div className={styles.options}>
                  {option.items &&
                    option.items.map((item: any, index: number) => (
                      <div key={index} className={styles.item}>
                        <span>{item.title}</span>
                        {item.warning && <span className={styles.warning}>{item.warning}</span>}
                        {item.subtitle && (
                          <span className={styles.itemSubtitle}>{item.subtitle}</span>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  )
}

export default FirstTabMenu
