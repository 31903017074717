// Models
import { Menu } from 'api/Api'

// Styles
import styles from './Footer.module.css'

interface FooterProps {
  data: Menu | null
}

const Footer = ({ data }: FooterProps) => {
  return (
    <div className={styles.footerContainer}>
      {/* Social Media Accounts */}
      <div className={styles.socialMediaContainer}>
        {data?.socialMedia?.instagramUrl && (
          <div
            className={styles.socialMediaIcon}
            onClick={() => window.open(data?.socialMedia?.instagramUrl, '_blank')}
          >
            <i className='fa-brands fa-instagram'></i>
          </div>
        )}
      </div>
      {/* Copyright */}
      <div className={styles.copyrightContainer}>
        <span>Copyright 2024 ©️ Menutica - All Rights Reserved.</span>
      </div>
    </div>
  )
}

export default Footer
