import ClickAwayListener from 'react-click-away-listener'
import styles from './AnouncementModal.module.css'

// Model
import { Menu } from 'api/Api'

interface AnouncementModalProps {
  data: Menu | null
  setShowAnouncementModal: (value: boolean) => void
}

const AnouncementModal = ({ data, setShowAnouncementModal }: AnouncementModalProps) => {
  return (
    <div className={styles.anouncementModalContainer}>
      {/* Inner Modal */}
      <ClickAwayListener onClickAway={() => setShowAnouncementModal(false)}>
        <div className={styles.innerModal}>
          {/* Close Btn */}
          <div className={styles.closeBtn} onClick={() => setShowAnouncementModal(false)}>
            <i className='fas fa-times'></i>
          </div>

          <div className={styles.imageContainer}>
            <img
              src={data?.announcement?.logoUrl || '/media/placeholders/placeholder4.png'}
              alt=''
            />
          </div>

          {/* Title */}
          {data?.announcement?.title && (
            <div className={styles.content}>
              <div className={styles.title}>
                {data?.announcement?.title || 'Some title for anouncement'}
              </div>
              <div className={styles.description}>
                {data?.announcement?.description ||
                  'Some placeholder text for anouncement.This is for similating the accual case of some anounce'}
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default AnouncementModal
