import styles from './GlobalLoading.module.css'

const GlobalLoading = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src='/media/logo/menuticaLogo.png' alt='logo' />
      </div>
    </div>
  )
}

export default GlobalLoading
