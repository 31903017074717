import styles from './Header.module.css'

import { Menu } from 'api/Api'
interface HeaderProps {
  selectedCategory: string | undefined
  setSelectedCategory: (category: string | undefined) => void
  data: Menu | null
}

const Header = ({ selectedCategory, setSelectedCategory, data }: HeaderProps) => {
  return (
    <div className={styles.headerContainer}>
      {/* Back Button */}
      {selectedCategory && (
        <div
          className={styles.backButton}
          onClick={() => {
            setSelectedCategory(undefined)
          }}
        >
          <i className='fa-solid fa-arrow-left'></i>
        </div>
      )}
      <img
        src={data?.logoUrl}
        alt='logo'
        onClick={() => {
          setSelectedCategory(undefined)
        }}
      />
    </div>
  )
}

export default Header
