export const data = [
  {
    title: null,
    items: [
      {
        title: 'Rice & Peas - £6',
      },
      {
        title: 'Plain Rice - £4',
      },
      {
        title: 'Hard Food - £5',
      },
      {
        title: 'Fries - £2',
      },
      {
        title: 'Mac & Cheese - £6',
      },
    ],
  },
  {
    title: null,
    items: [
      {
        title: 'Honey BBQ Wings - £4.99',
      },
      {
        title: 'Sweet Chilli Wings - £4.99',
      },
      {
        title: 'Jerk Wings - £4.99',
      },
      {
        title: 'Fried Wings - £4.99',
      },
      {
        title: 'Mixed Wings - £4.99',
      },
    ],
  },
  {
    title: null,
    items: [
      {
        title: 'Lobster Tail - £10',
      },
      {
        title: 'Seasoned Prawns - £7',
      },
      {
        title: 'Battered Prawns - £7',
      },
      {
        title: 'Mixed Prawns - £7',
      },
      {
        title: 'Tacos - £5',
      },
    ],
  },
  {
    title: null,
    items: [
      {
        title: 'Waffles - £2',
      },
      {
        title: 'Corn - £2',
      },
      {
        title: 'Plantain - £2',
      },
      {
        title: 'Festival - £3.50',
      },
      {
        title: 'Coleslaw - £2',
      },
      {
        title: 'Potato Salad - £3.50',
      },
    ],
  },
  {
    title: null,
    items: [
      {
        title: 'Oxtail - £8',
      },
      {
        title: 'Curry Goat - £10',
      },
      {
        title: 'Fried Chicken - £8',
      },
      {
        title: 'Jerk Chicken - £8',
      },
    ],
  },
]
