import { useState } from 'react'
import classNames from 'classnames'

// Styles
import styles from './VibesTakeaway.module.css'

// Tabs
import SecondTabMenu from './components/SecondTabMenu/SecondTabMenu'
import FirstTabMenu from './components/FirstTabMenu/FirstTabMenu'

const VibesTakeaway = () => {
  const [menuTab, setMenuTab] = useState('kitchenMenu')
  //   Set this active when development is done
  const [showAnouncement, setShowAnouncement] = useState(true)

  //   Change page title - Make it dynamic
  document.title = 'Menutica | Vibes Takeaway'

  return (
    <div className={styles.container}>
      {/* Header- Name */}
      <div className={styles.headerContainer}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src='/media/icons/WhatsApp_icon.png'
            alt=''
            style={{
              width: '50px',
            }}
          />
          07377360054
        </div>
        <div
          style={{
            margin: '10px 0',
            textAlign: 'center',
            fontSize: '16px',
          }}
        >
          Open Fri & Sat - 3pm - 12am
        </div>
        <img alt='vibes-takeaway' src='/media/logo/vibes-takeaway-logo.webp' />
        <span className={styles.entityName}>Vibes Takeaway</span>
      </div>

      {/* Menu Tab */}
      <div className={styles.menuTabContainer}>
        <div
          onClick={() => setMenuTab('kitchenMenu')}
          className={classNames(styles.menuTab, menuTab === 'kitchenMenu' ? styles.active : '')}
        >
          Kitchen Menu
        </div>
        <div
          onClick={() => setMenuTab('sides')}
          className={classNames(styles.menuTab, menuTab === 'sides' ? styles.active : '')}
        >
          Sides & Appetisers
        </div>
      </div>

      {/* First Tab  */}
      {menuTab === 'kitchenMenu' && <FirstTabMenu />}

      {/* Second Tab */}
      {menuTab === 'sides' && <SecondTabMenu />}

      {/* Anouncement */}
      {showAnouncement && (
        <div className={styles.anouncementContainer}>
          {/* Close  */}
          <div className={styles.closeAnouncement} onClick={() => setShowAnouncement(false)}>
            X
          </div>

          <span> Get A Free 1 Ltr Bottle of Drink </span>
          <br />
          <span>When You Order Any Platter Meal Between 3pm - 6pm</span>
          <br />
          <span className={styles.warningInfoText}>
            If you have a food allergy or a special dietary requirement please inform a member of
            our staff before you place your order
          </span>
        </div>
      )}

      {/* Footer */}
    </div>
  )
}

export default VibesTakeaway
