export const data = [
  {
    title: '<span>Vibes Meat Dish</span> <br/> <span>Platter  £18</span>',
    guide:
      '<span>How to order Vibes Meat Dish Platter</span> <br/><span>1- Select Two Choice of Meats</span><br/> <span>2- Select 1 Side</span> <br/> <span>3- Select 1 Salad</span>',
    options: [
      {
        title: 'Meat Dishes',
        items: [
          {
            title: 'Oxtail',
            subtitle: null,
          },
          {
            title: 'Curry Goat',
            subtitle: null,
          },
          {
            title: 'Fried Chicken',
            subtitle: null,
          },
          {
            title: 'Jerk Chicken',
            subtitle: null,
          },
        ],
      },
      {
        title: 'Sides',
        items: [
          {
            title: 'Rice & Peas',
            subtitle: null,
          },
          {
            title: 'Fries',
            subtitle: null,
          },
          {
            title: 'Plain Rice',
            subtitle: null,
          },
          {
            title: 'Hard Food',
            subtitle: '(Yam, Banana, Dumpling, Sweet Potatoes)',
          },
        ],
      },
      {
        title: 'Salads',
        items: [
          {
            title: 'Coleslaw',
            subtitle: null,
          },
          {
            title: 'Potato Salad',
            subtitle: null,
          },
        ],
      },
    ],
  },
  {
    title: 'Vibes Seafood Platter £27.99',
    guide:
      '<span>How to order Vibes Seafood Platter</span> <br/><span>1- Select 1 Choice of Wings</span><br/> <span>2- Select 1 Choice of Prawns</span> <br/> <span>3- Select 1 Choice Side 1</span><br/> <span>4- Select 1 Choice Side 2</span>',
    warning: 'Our Seasoned Vibes Lobster Tail Is Included With This Meal',
    options: [
      {
        title: 'Wings',
        items: [
          {
            title: 'Honey BBQ Wings',
            subtitle: null,
          },
          {
            title: 'Sweet Chilli Wings',
            subtitle: null,
          },
          {
            title: 'Jerk Wings',
            subtitle: null,
          },
          {
            title: 'Fried Wings',
            subtitle: null,
          },
          {
            title: 'Mixed Wings',
            subtitle: null,
          },
        ],
      },
      {
        title: 'Prawns',
        items: [
          {
            title: 'Seasoned Prawns',
            subtitle: null,
          },
          {
            title: 'Battered Prawns',
            subtitle: null,
          },
          {
            title: 'Mixed Prawns',
            subtitle: null,
          },
        ],
      },
      {
        title: 'Side 1 ',
        items: [
          {
            title: 'Mac & Cheese',
            subtitle: null,
          },
          {
            title: 'Fries',
            subtitle: null,
          },
          {
            title: 'Plain Rice',
            subtitle: null,
          },
          {
            title: 'Rice & Peas',
            subtitle: null,
          },
        ],
      },
      {
        title: 'Side 2',
        warning: 'A Waffle is included in this side option',
        items: [
          {
            title: 'Corn',
            subtitle: null,
          },
          {
            title: 'Festival',
            subtitle: null,
          },
        ],
      },
    ],
  },
  {
    title: 'Vibes Seafood Bag £20',
    guide: null,
    underTitleOptions:
      'With 10 Large Prawns, 2 Crab Legs, 2 Boiled Eggs, 5 Potatoes, 2 Corn of the Cobs and a sausage',
  },
  {
    title: 'Vibes Tacos £8',
    guide: null,
    options: [
      {
        title: null,
        items: [
          {
            title: 'Soft Tacos OR Shell Tacos',
            subtitle: null,
            warning: 'This meal comes with fries',
          },
          {
            title: 'Jerk Tacos',
            subtitle: null,
            warning: null,
          },
          {
            title: 'Oxtail Tacos',
            subtitle: null,
          },
          {
            title: 'Veggie Tacos',
            subtitle: '( Roasted Couliflower, Sweet Potato, Avocado, Cheese )',
          },
        ],
      },
    ],
  },
  // {
  //   title: 'Patties £3',
  //   guide: null,
  //   options: [
  //     {
  //       title: null,
  //       items: [
  //         {
  //           title: 'Beef Patty',
  //           subtitle: null,
  //         },
  //         {
  //           title: 'Chicken Patty',
  //           subtitle: null,
  //         },
  //         {
  //           title: 'Vegatable Patty',
  //           subtitle: null,
  //         },
  //         {
  //           title: 'Lamb Patty',
  //           subtitle: null,
  //         },
  //       ],
  //     },
  //   ],
  // },
]
