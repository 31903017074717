import { useState } from 'react'
// Models
import { Menu } from 'api/Api'

import styles from './Theme1.module.css'
// Components
import Header from './components/Header/Header'
import Categories from './components/Categories/Categories'
import Footer from './components/Footer/Footer'
import CategoryDetails from './components/CategoryDetails/CategoryDetails'
import AnouncementModal from './components/AnouncementModal/AnouncementModal'
import CroniesAnouncementModal from './components/CroniesAnouncementModal/CroniesAnouncementModal'

interface Theme1Props {
  data: Menu | null
}

const Theme1 = ({ data }: Theme1Props) => {
  const [showAnouncementModal, setShowAnouncementModal] = useState<boolean>(
    data?.announcement ? true : false,
  )
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined)

  const [croniesAnouncement, setCroniesModal] = useState<boolean>(true)
  return (
    <div className={styles.themeWrapper}>
      {/* Anouncement Modal */}
      {showAnouncementModal && (
        <AnouncementModal data={data} setShowAnouncementModal={setShowAnouncementModal} />
      )}

      {/* Cronies anouncement Modal */}

      {/* Header */}
      <Header
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        data={data}
      />
      {/* Categories */}
      {!selectedCategory ? (
        <Categories data={data} setSelectedCategory={setSelectedCategory} />
      ) : (
        <CategoryDetails data={data} selectedCategory={selectedCategory} />
      )}

      {/* Footer */}
      <Footer data={data} />
    </div>
  )
}

export default Theme1
