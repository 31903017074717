import { Menu } from 'api/Api'
import styles from './CategoryDetails.module.css'
import DetailItem from './components/DetailItem/DetailItem'
import { useEffect } from 'react'
interface CategoryDetailsProps {
  data: Menu | null
  selectedCategory: string
}
const CategoryDetails = ({ data, selectedCategory }: CategoryDetailsProps) => {
  const details = data?.categories?.find((category) => category.name === selectedCategory)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [selectedCategory])

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.categoryTitle}>
        <span>{selectedCategory}</span>
      </div>

      {details?.items?.map((item, idx) => <DetailItem item={item} index={idx} />)}
    </div>
  )
}

export default CategoryDetails
