// Themes
import Theme1 from 'components/Themes/Theme1/Theme1'
// Models
import { Menu } from 'api/Api'
// Components
import GlobalLoading from 'components/GlobalLoading/GlobalLoading'

interface ThemePickerProps {
  selectedTheme: string | undefined
  data: Menu | null
}

const ThemePicker = ({ selectedTheme, data }: ThemePickerProps) => {
  if (selectedTheme === 'default') {
    return <Theme1 data={data} />
  }

  if (!selectedTheme) {
    return <GlobalLoading />
  }

  return <></>
}

export default ThemePicker
