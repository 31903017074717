// Styles
import styles from './SingleCategory.module.css'

// Models
import { MenuCategory } from 'api/Api'

interface SingleCategoryProps {
  category: MenuCategory
  setSelectedCategory: (category: string | undefined) => void
}

const SingleCategory = ({ category, setSelectedCategory }: SingleCategoryProps) => {
  return (
    <div className={styles.category} onClick={() => setSelectedCategory(category.name)}>
      <img src={category.imageUrl || '/media/placeholders/placeholder4.png'} alt={category.name} />
      <p>{category.name}</p>
    </div>
  )
}

export default SingleCategory
