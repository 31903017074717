import styles from './CroniesKanlikavak.module.css'

const CroniesKanlikavak = () => {
  return (
    <div className={styles.container}>
      <img src='/media/cronies-kanlikavak/1.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/2.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/3.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/4.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/5.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/6.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/7.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/8.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/9.jpg' alt='' />
      <img src='/media/cronies-kanlikavak/10.jpg' alt='' />
    </div>
  )
}

export default CroniesKanlikavak
